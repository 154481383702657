<template>
    <div class="box">
        <div class="jbxx">
            <div class="jbtop">
                <div class="icon"></div>
                <div class="tit">基本配置</div>
            </div>
            <el-form ref="form" :model="form" label-width="200px">
              <el-row>
                <!-- <el-col :span="8">
                    <el-form-item label="是否允许商家注册">
                      <el-switch
                        v-model="form.REGISTER"
                        active-color="#13ce66"
                        inactive-color="#ff4949"
                        active-value="1"
                        inactive-value="0">
                      </el-switch>
                   </el-form-item>  
                </el-col> -->
                <el-col :span="8">
                    <el-form-item label="商户余额最小提现金额">
                      <el-input v-model="form.MINPRICE"></el-input>
                   </el-form-item>  
                </el-col>
                
              </el-row>
              
            </el-form>
            <div class="jbtop" style="margin-top: 50px;">
                <div class="icon"></div>
                <div class="tit">基础配置 | 登录界面-温馨提示</div>
                
            </div>
            <div class="edi">
                    <div>登录界面-温馨提示：</div>
                    <div>
                      <quill-editor
                     class='editor'
                     v-model="form.WXTSCONTENT"
                     ref="myQuillEditor"
                     :options="editorOption"
                     @blur="onEditorBlur($event)"
                     @focus="onEditorFocus($event)"
                     @change="onEditorChange($event)"
                     @ready="onEditorReady($event)">
                    </quill-editor>
                    </div>
            </div>
            <div class="jbtop" style="margin-top: 50px;">
                <div class="icon"></div>
                <div class="tit">基础配置 | 小尼甄选小程序隐私政策</div>
                
            </div>
            <div class="edi">
                    <div>小尼甄选小程序隐私政策：</div>
                    <quill-editor
                     class='editor'
                     v-model="form.YSCONTENT"
                     ref="myQuillEditor"
                     :options="editorOption"
                     @blur="onEditorBlur($event)"
                     @focus="onEditorFocus($event)"
                     @change="onEditorChange($event)"
                     @ready="onEditorReady($event)">
                    </quill-editor>
            </div>
            <div class="jbtop" style="margin-top: 50px;">
                <div class="icon"></div>
                <div class="tit">基础配置 | 小尼甄选用户服务协议</div>
                
            </div>
            <div class="edi">
                    <div>小尼甄选用户服务协议：</div>
                    <quill-editor
                     class='editor'
                     v-model="form.FWCONTENT"
                     ref="myQuillEditor"
                     :options="editorOption"
                     @blur="onEditorBlur($event)"
                     @focus="onEditorFocus($event)"
                     @change="onEditorChange($event)"
                     @ready="onEditorReady($event)">
                    </quill-editor>
            </div>
            <div class="jbtop" style="margin-top: 50px;">
                <div class="icon"></div>
                <div class="tit">基础配置 | 小尼甄选甄选一卡通兑换服务协议</div>
                
            </div>
            <div class="edi">
                    <div>小尼甄选甄选一卡通兑换服务协议：</div>
                    <quill-editor
                     class='editor'
                     v-model="form.YKTCONTENT"
                     ref="myQuillEditor"
                     :options="editorOption"
                     @blur="onEditorBlur($event)"
                     @focus="onEditorFocus($event)"
                     @change="onEditorChange($event)"
                     @ready="onEditorReady($event)">
                    </quill-editor>
            </div>
            <div class="jbtop" style="margin-top: 50px;">
                <div class="icon"></div>
                <div class="tit">基础配置 | 小尼甄选用户充值服务协议</div>
                
            </div>
            <div class="edi">
                    <div>小尼甄选用户充值服务协议：</div>
                    <quill-editor
                     class='editor'
                     v-model="form.CZFWCONTENT"
                     ref="myQuillEditor"
                     :options="editorOption"
                     @blur="onEditorBlur($event)"
                     @focus="onEditorFocus($event)"
                     @change="onEditorChange($event)"
                     @ready="onEditorReady($event)">
                    </quill-editor>
            </div>
            <div class="jbtop" style="margin-top: 50px;">
                <div class="icon"></div>
                <div class="tit">基础配置 | 到店服务协议</div>
                
            </div>
            <div class="edi">
                    <div>到店服务协议：</div>
                    <quill-editor
                     class='editor'
                     v-model="form.DDFWCONTENT"
                     ref="myQuillEditor"
                     :options="editorOption"
                     @blur="onEditorBlur($event)"
                     @focus="onEditorFocus($event)"
                     @change="onEditorChange($event)"
                     @ready="onEditorReady($event)">
                    </quill-editor>
            </div>
            <div class="jbtop" style="margin-top: 50px;">
                <div class="icon"></div>
                <div class="tit">基础配置 | 小尼甄选帮我买服务协议</div>
                
            </div>
            <div class="edi">
                    <div>小尼甄选帮我买服务协议：</div>
                    <quill-editor
                     class='editor'
                     v-model="form.BWMCONTENT"
                     ref="myQuillEditor"
                     :options="editorOption"
                     @blur="onEditorBlur($event)"
                     @focus="onEditorFocus($event)"
                     @change="onEditorChange($event)"
                     @ready="onEditorReady($event)">
                    </quill-editor>
            </div>
            <div class="jbtop" style="margin-top: 50px;">
                <div class="icon"></div>
                <div class="tit">基础配置 | 平台商户入驻服务框架协议</div>
                
            </div>
            <div class="edi">
                    <div>平台商户入驻服务框架协议：</div>
                    <quill-editor
                     class='editor'
                     v-model="form.SHRZCONTENT"
                     ref="myQuillEditor"
                     :options="editorOption"
                     @blur="onEditorBlur($event)"
                     @focus="onEditorFocus($event)"
                     @change="onEditorChange($event)"
                     @ready="onEditorReady($event)">
                    </quill-editor>
            </div>
            <div class="butt">
                <!-- <el-button style="width: 140px;height: 50px;" @click="$router.push('/auditBusinessone')">取消</el-button> -->
                <el-button style="width: 140px;height: 50px;" type="primary" @click="onSubmit">保存</el-button>
            </div>
        </div>
        
    </div>
  </template>
  
  <script>
 import { quillEditor } from 'vue-quill-editor'
 import 'quill/dist/quill.core.css'
 import 'quill/dist/quill.snow.css'
 import 'quill/dist/quill.bubble.css'
 export default {
  components: {   //界面组件引用
    quillEditor
  },
  data() {
    return {
        form: {
          MINPRICE:'',
          WXTSCONTENT:'',
          YSCONTENT:'',
          // ZCCONTENT:'',
          FWCONTENT:'',
          YKTCONTENT:'',
          CZFWCONTENT:'',
          DDFWCONTENT:'',
          BWMCONTENT:'',
          SHRZCONTENT:'',
        },
        editorOption: {
          // some quill options 编辑器配置
          // scrollingContainer:true,
        },
        tableData:[
            {name:'wdz'}
        ],
        value: '1',
        showDialog:false,


    };
  },
  mounted() {
    var that = this;
    that.$http.post("/setup/queryOne", {
        id:14,
        setupType:9,
      }).then(function (response) {
        console.log(response.data.data);
        if (response.data.code == 200) {
          that.form =  response.data.data.setupContentJson


         
        }
      });
  },
  methods: {
    handleSuccess(response, file, fileList){
      console.log( response.data.url);
    },
    onEditorBlur(e){
      console.log('onEditorBlur',e)
    },
    onEditorFocus(e){
      console.log('onEditorFocus',e)
    },
    onEditorChange(e){
      console.log('onEditorChange',e)
    },
    onEditorReady(e){
      console.log('onEditorReady',e)
    },
    // onSubmit(){
    //     // this.$router.push('/staff')
    // },
    add(){
        this.showDialog = true
    },
    onSubmit(){
      var that = this;
	    that.$http.post("/setup/update", {
        id:14,
        setupType:9,
        setupContent:that.form
      }).then(function (response) {
        console.log(response.data.data);
        if (response.data.code == 200) {
          that.$message({
            type:'success',
            message: '配置成功'
          });
         
        }
      });
    }
  }
};
  </script>
  
  <style lang="less" scoped>
  .box {
    background-color: #fff;
    padding-top: 20px;
    .jbxx {
        .jbtop {
            background-color: #eef1f6;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            padding: 20px;
            margin: 0 10px;
            margin-bottom: 40px;
            .icon {
                width: 6px;
                height: 19px;
                border-radius: 10px;
                background-color: #f77f1f;
                margin-right: 10px;
            }
            .tit {
                font-weight: 700;
            }
            
            
        }
        .edi {
                margin-left: 20px;
                min-height: 450px;
                // display: flex;
                .editor {
                width: 817px;
                min-height: 375px;
                margin-top: 20px;
                margin-left: 100px;
               }
            }
    }
    .butt {
        display: flex;
        justify-content: center;
        margin-top:50px;
        padding-bottom: 30px;
        
    }
    .tab {
        padding: 20px;
    }
    .dia {
      .diafour {
            display: flex;
            justify-content: space-between;
            padding: 20px 230px;
        }
    }
  }
  </style>
  